<style>
</style>
<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="12" sm="12" md="12">
            <span class="mx-2 secondary--text display-2 float-left">Anexos</span>
        </v-col>
        <v-col cols="12" sm="12" md="12">
            <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista" :page.sync="page" :loading="loadingTable" item-key="cianId" :items-per-page="20" hide-default-footer sort-by="cianFecha">
                    <template v-slot:header>
                         <Dfiltros v-if="verFiltros" :onBuscar="search" v-bind:filtros="filtros" />
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true" v-if="cifaId || getPermiso('crearAnexos')">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items }">
                        <v-container class="pt-0">
                            <v-row  :class="($vuetify.theme.dark)?'grey darken-4':'grey lighten-3'">
                                <v-col v-for="item in items" :key="item.cianId"  cols="12" sm="12" md="3" lg="3" >
                                    <v-card elevation="0" class="rounded-xl">
                                        <v-card-title>
                                            <v-row no-gutters>
                                                 <v-col cols="12" class="text-center">
                                                     <span class="heading" >{{ new Date(item.cianFecha).yyyymmdd() +' '+  new Date(item.cianFecha).hhmm()}}</span>
                                                     <v-menu bottom left>
                                                        <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon outlined v-bind="attrs" v-on="on">
                                                                    <v-icon class="material-icons-two-tone">more_vert</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item @click="ver(item.cianId,item.cianExt)">
                                                                    <v-list-item-icon>
                                                                        <v-icon color="primary">mdi-eye</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>Ver</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="user.role.indexOf('ADMIN') > -1 || ((item.userId == user.id) && (cifaId || getPermiso('eliminarAnexos')))"  @click="eliminar(item.cianId)">
                                                                    <v-list-item-icon>
                                                                        <v-icon color="error">mdi-delete</v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-title>eliminar</v-list-item-title>
                                                                </v-list-item>
                                                        </v-list>
                                                        </v-menu>
                                                </v-col>
                                               
                                                <v-col cols="12" class="text-center">
                                                    <template v-if="item.cianExt=='pdf'">
                                                        <v-icon color="error" style="font-size:180px;">mdi-file-pdf-box</v-icon>
                                                    </template>
                                                    <template v-else>
                                                        <v-icon color="blue" style="font-size:180px;">mdi-image</v-icon>
                                                    </template>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text>
                                            <v-row no-gutters>
                                                <v-col cols="12" sm="12" md="12" lg="12">
                                                    <v-row no-gutters>
                                                        <v-col cols="12" sm="12" md="12" lg="12">
                                                            <span class="headline primary--text">{{item.cianNombre}}</span>
                                                        </v-col>
                                                        <v-col cols="12" sm="12" md="12" lg="12">
                                                            <span class="caption primary--text">{{item.tipo? item.tipo.comaNombrelargo:''}}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:footer>
                        <Paginacion v-on:cambiarPagina="page = $event" :onSearch="search" v-bind:propiedades="{filtros,page,pageCount,Count}" />
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>

    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" persistent max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Anexo</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="loadingForm" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field  label="Nombre" :error-messages="errores['cianNombre']"  outlined v-model="form.cianNombre"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete v-model="form.cianTipo" :error-messages="errores['cianTipo']"  :items="tiposanexos"  item-text="comaNombrelargo" item-value="comaId" label="tipo" clearable outlined></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12">
                                    <v-file-input :rules="rules" :error-messages="errores['file']" accept="image/png, image/jpeg, image/jpg, application/pdf"   outlined v-model="file" show-size label="Anexo" @change="onFileChange"></v-file-input>
                                </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary" :loading="loadingGuardar" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
    <v-dialog v-model="dialog2" persistent max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">Anexo</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row v-if="loadingForm" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" sm="12" md="12" v-if="tipo != 'pdf'">
                                    <img :src="img" class="rounded-xl" width="100%" height="100%">
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="tipo == 'pdf'">
                                <object>
                                <embed  type="text/html" width="100%" height="700" :src="'data:application/pdf;base64,'+img" />
                                </object>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary" text @click="dialog2 = false">Cerrar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
</v-container>
</template>
<script>
import generales from '../mixins/generales.js';

export default {
    mixins: [generales],
    props:{
        copeId:Number,
        cifaId:Number,
        copeIdentificacion:String,
        userId:Number
    },
    data() {
        return {
            rules: [
                value => !value || value.size < (2000000 * 10)|| 'Avatar size should be less than 2 MB!',
            ],
            url: "cita/anexos/",
            img:null,
            form: {
                cianId: null,
                cianFecha: null,
                cianNombre:null ,
                cianTipo:null ,
                cifaId:null ,
                copeId:null,
                cianRuta:null ,
                userId: null,
                cianExt: null,
                cianMimeType:null
            },
             filtros: [
                {
                    campo: "cianNombre",
                    text: "Nombre",
                    value: null,
                    tipo: "text",
                    search: true,
                    isLoading: false,
                },
                {
                    campo: "cianTipo",
                    text: "Tipo",
                    value: null,
                    tipo: "lista",
                    /*si es tipo lista descomentar esta parte*/
                    searchNormal: true,
                    isLoading: false,
                    lista: [],
                    listatext: "comaNombrelargo",
                    listavalue: "comaId"
                },
                {
                    campo:"cianFecha",
                    text: "Fecha",
                    value: null,
                    tipo: "fecha",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },
                {
                    campo:"cianFecharango",
                    text: "Rango de fecha",
                    value: [],
                    tipo: "rango",
                    menu: false,
                    searchNormal: true,
                    isLoading: false,
                    /*si es tipo lista descomentar esta parte*/
                    /* lista: [],
                    listatext: "nombre del campo que se mostrara",
                    listavalue: "nombre del valor que se mandara"*/
                },
            ],
            file:null,
            tiposanexos:[],
            tipo:null
        };
    },
    created: async function () {
        this.filtros[1].lista = this.tiposanexos = await this.$apiService.index("sistema/maestra/index/TBL_TIPODEANEXOS").then(data => data);
        await this.search(this.filtros);
    },

    methods: {
        onFileChange(file) {
            this.file = file;
        },
        async search(filtros) {
            this.lista = this.listaCopia = [];
            this.loadingTable = true;
            const data = await this.$apiService.index(`cita/anexos/buscar?${this.filtrar(filtros,this.page)}${this.copeId ?'&copeId='+this.copeId:'' }${this.cifaId ?'&cifaId='+this.cifaId:'' }`).then(data => data);
            if (data) {
                this.lista = this.listaCopia = data.rows;
                this.Count = data.count;
                this.pageCount = Math.ceil((data.count) / 20);
            }
            this.loadingTable = false;
        },
        async agregar() {
            try {
                this.errores = [];
                this.loadingGuardar = true;
                let formData = new FormData();
                if(this.file){
                    var filename = this.file.name;
                    this.form.cianMimeType = this.file.type;
                    this.form.cianExt = filename.split('.').pop();
                }
                
                this.form.userId = this.user.id;
                formData.append('file', this.file);
                formData.append('type', this.form);
                formData.append('cianId', this.form.cianId);
                formData.append('cianNombre', this.form.cianNombre);
                formData.append('cianTipo', this.form.cianTipo);
                formData.append('cifaId', this.cifaId);
                formData.append('copeId', this.copeId);
                formData.append('cianRuta', this.form.cianRuta);
                formData.append('userId', this.form.userId);
                formData.append('cianExt', this.form.cianExt);
                formData.append('cianMimeType', this.form.cianMimeType);
                formData.append('copeIdentificacion', this.copeIdentificacion);
                let data = (this.isNew) ? await this.$apiService.createFile(`${this.url}create`, formData).then((data) => data) :
                    await this.$apiService.updateFile(`${this.url}update/${this.form.cianId}`, formData).then((data) => data);
                if (data) {
                    this.close();
                    this.$swal.fire({
                        title: "Completado!",
                        text: "Registro exitoso",
                        icon: "success",
                        confirmButtonText: "Ok"
                    });
                    await this.search(this.filtros);
                }

            } catch (error) {
                if (error.response && 422 == error.response.status) {
                    this.errores = error.response.data.errors;
                } else {
                    if (error.response && 403 == error.response.status) {
                        this.$router.push('/403');
                    } else {
                        this.$swal.fire({
                            title: "Algo sucedio!",
                            text: "contacta con el administrador",
                            icon: "error"
                        });
                    }

                }
            }
            this.loadingGuardar = false;
        },
        async eliminar(id) {
            try {
                let result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    var data = await this.$apiService.delete(`${this.url}delete/${id}`).then(data => data);
                    if (data) {
                        await this.search(this.filtros);
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async ver(id,tipo){
            this.tipo = tipo;
            this.dialog2 = true;
            this.loadingForm = true;
            
            let data = await this.$apiService.index(`${this.url}getAnexo/${id}`).then(data => data);
            if (data.mensaje == undefined && data.mensaje != 'No data') {
                this.img =  data;
            }
            
            this.loadingForm = false;
        },
        limpiar() {
            this.form = {
                cianId: null,
                cianFecha: null,
                cianNombre:null ,
                cianTipo:null ,
                cifaId:null ,
                copeId:null,
                cianRuta:null ,
                userId: null,
                cianExt: null,
                cianMimeType:null
            };
        },
        close() {
            this.errores = [];
            this.isNew = true;
            this.dialog = false;
            this.dialog2 = false;
            this.limpiar();
        }
    }
};
</script>
