<style>

</style>

<template>
    <div>
        <v-container lighten-5>
            <v-data-iterator :items="lista" :page.sync="page" :items-per-page="10" item-key="citaId" single-expand hide-default-footer sort-desc sort-by="tblCitaFacturaciones[0].cifaFecha" class="elevation-1">
                <template v-slot:header>
                    <Dfiltros :onBuscar="buscar" v-bind:filtros="filtros" />
                    <v-toolbar dark color="primary darken-3" class="mb-1">
                        <v-toolbar-title class="white--text">Entregas</v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                </template>
                <template v-slot:default="{ items, isExpanded, expand }">
                    <v-row align="center" justify="center">
                        <v-col v-for="item in items" :key="item.citaId" cols="12" sm="12" md="10" lg="10">
                            <v-card>
                                <v-card-title class="subtitle-1 font-weight-bold">
                                    <v-row no-gutters>

                                        <v-col cols="9" sm="9" md="9" lg="9">
                                            <v-row no-gutters>

                                                <v-col cols="12" sm="5" md="5" lg="5">
                                                    PACIENTE <span class="primary--text">
                                                        {{ item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.copnApellido1 }}
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="7" md="7" lg="7">
                                                    FECHA <span class="green--text"> {{item.tblCitaFacturaciones[0].cifaFecha}}</span>
                                                </v-col>

                                                <v-col cols="12" sm="5" md="5" lg="5">
                                                    IDENT. <span class="primary--text">
                                                        {{item.tblConfPersonaspacientes[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}

                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="7" md="7" lg="7">
                                                    EDAD <span class="primary--text">{{ calcularEdad(item.tblConfPersonaspacientes[0].coppFechanacimiento) }}
                                                    </span>
                                                </v-col>
                                                <v-col cols="12" sm="5" md="5" lg="5">
                                                    ESTADO <template v-if="item.citaTipo == 4">
                                                        <span :class="((getEstado(item.tblCitaRecetarios)<=30 )?'red':(getEstado(item.tblCitaRecetarios)<=99 )?'yellow':(getEstado(item.tblCitaRecetarios)==100 )?'green':'grey')+'--text'">{{getEstado(item.tblCitaRecetarios)}} %</span>
                                                    </template>
                                                    <template v-else>
                                                        <span :class="((getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)<=30 )?'red':(getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)<=99 )?'yellow':(getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)==100 )?'green':'grey')+'--text'">{{getEstado(item.tblCitaFacturaciones[0].tblCitaRecetarios)}} %</span>
                                                    </template>
                                                </v-col>

                                            </v-row>
                                        </v-col>
                                        <v-col cols="3" sm="3" md="3" lg="3">
                                            <v-tooltip top v-if="item.estado.comaNombrecorto != 'CANCELADA'">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn outlined color="success" class="mr-2" small v-on="on" :to="{ name: 'Entregafactura',params: { cita: item} }">
                                                        <v-icon>mdi-desktop-mac-dashboard</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Facturar</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn outlined small color="error" v-on="on" @click="eliminar(item.citaId)">
                                                        <v-icon>delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <v-row no-gutters>

                                        <v-col cols="12" sm="12" md="3" lg="3">
                                            EPS <span class="primary--text">
                                                <template v-if="!item.tblCitaFacturaciones[0].cocoId">
                                                    {{ item.tblCitaFacturaciones[0].tblConfBasedatospacientes[0].tblConfBasedato.tblConfContratos[0].tblConfPersonasempresas[0].coemNombre }}
                                                </template>
                                                <template v-if="item.tblCitaFacturaciones[0].cocoId">
                                                    {{ item.tblCitaFacturaciones[0].tblConfContrato.tblConfPersonasempresas[0].coemNombre }}
                                                </template>
                                            </span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="4" lg="4">
                                            EMPLEADO
                                            <span class="primary--text"> {{ item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnNombre1 + " " +item.tblConfPersonasempleados[0].tblConfPersonasnaturale.copnApellido1 }} </span>
                                            <span class="">{{item.tblConfPersonasempleados[0].tblConfPersonasnaturale.tblConfPersona.copeIdentificacion}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="5" lg="5">
                                            SERVICIO <span class="primary--text"> {{ item.tblConfContratosxservicios[0].tblConfServicios[0].coseNombre}}</span>
                                        </v-col>
                                        <v-col cols="12" sm="12" md="12" lg="12">
                                            <v-row>
                                                <v-col cols="1" sm="1" md="1" lg="1">
                                                    <v-btn color="grey" icon outlined @click="() => expand(item, !isExpanded(item))">
                                                        <v-icon v-if="!isExpanded(item)">mdi-chevron-down</v-icon>
                                                        <v-icon v-else>mdi-chevron-up</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                <v-col cols="11" sm="11" md="11" lg="11">
                                                    <span class="headline primary--text">MEDICAMENTOS</span>
                                                </v-col>
                                            </v-row>

                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text v-if="isExpanded(item)">
                                    <template v-for="items in (item.citaTipo)?item.tblCitaRecetarios:item.tblCitaFacturaciones[0].tblCitaRecetarios ">

                                        <v-chip class="ma-2" :key="items.cireId" small outlined label>{{ 'Medicamento: '+items.tblMediMedicamento.mediNombre +' - ' + items.tblMediMedicamento.mediConcentracion + ' ' +items.tblMediMedicamento.unidad.comaNombrecorto + ' - Cada '+ items.cireFrecuenciahoras+' horas - Durante '+ items.cireDuraciondias+' dias - Cantidad: '+items.cireCantidadprescrita + ' - Entragados: ' + items.cireEntregado + ' - Pendientes: '+(items.cirePendiente) }}</v-chip>

                                    </template>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:no-data>
                    <v-row class="mt-2" align="center" justify="center">
                        <v-col cols="10" sm="10" md="10" lg="10">
                            <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                No se encontro nada.
                            </v-alert>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:footer>
                    <v-row class="mt-2 pb-5" align="center" justify="center">
                        <span class="mr-4 grey--text">
                            Pagina {{ page }} de {{ pageCount }}, Total resultados: {{(Count)}}
                        </span>
                        <v-pagination circle v-model="page" @input="buscar(filtros)" @next="buscar(filtros)" @previous="buscar(filtros)" :total-visible="10" :length="pageCount"></v-pagination>

                    </v-row>
                </template>
            </v-data-iterator>

        </v-container>

    </div>
</template>

<script>
export default {
  data() {
    return {
      guardopaciente: false,
      menu: false,
      tab: "tab-1",
      tipo: 'CAPITADO',
      Count: 0,
      page: 1,
      pageCount: 0,
      dialog: false,
      isNew: true,
      copeId: null,
      cambio: null,
      isLoading: false,
      searchP: null,
      meses: ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"],
      bd: [],
      url: "cita/citas/",
      user: this.$cookies.get("user"),
      token: this.$cookies.get("token"),

      nameRules: [v => !!v || "Campo requirido"],
      pacientes: [],
      tipoestado: [],

      filtros: [{
          campo: 'cifa_fecha',
          text: "Fecha",
          value: null,
          tipo: "fecha",
          menu: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },

        {
          campo: '',
          text: "Estado",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [{
            comaNombrelargo: 'COMPLETO',
            comaId: 'COMPLETO'
          }, {
            comaNombrelargo: 'INCOMPLETO',
            comaId: 'INCOMPLETO'
          }, {
            comaNombrelargo: 'NO ENTREGADO',
            comaId: 'NO ENTREGADO'
          }],
          listatext: "comaNombrelargo",
          listavalue: "comaId"
        }, {
          campo: '',
          text: "Paciente",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "tblConfPersonasnaturales[0].copnNombre1",
          listavalue: "tblConfPersonasnaturales[0].tblConfPersonaspacientes[0].coppId",
          buscar: 'paciente',
          isLoading: false,
          search: null,
          //filter: (item, queryText, itemText) => (item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + item.tblConfPersonasnaturales[0].copnApellido2).indexOf(queryText.toUpperCase()) > -1 || item.copeIdentificacion.indexOf(queryText) > -1,
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasnaturales[0].copnNombre1 + ' ' + data.item.tblConfPersonasnaturales[0].copnNombre2 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido1 + ' ' + data.item.tblConfPersonasnaturales[0].copnApellido2,
              subtitle: data.item.copeIdentificacion
            }
          },
        },
        {
          campo: '',
          text: "Rango de fecha",
          value: [],
          tipo: "rango",
          menu: false,
          searchNormal: true,
          isLoading: false,
          /*si es tipo lista descomentar esta parte*/
          /* lista: [],
          listatext: "nombre del campo que se mostrara",
          listavalue: "nombre del valor que se mandara"*/
        },
        {
          text: "Contrato",
          value: null,
          tipo: "lista",
          /*si es tipo lista descomentar esta parte*/
          lista: [],
          listatext: "cocoNombre",
          listavalue: "cocoId",
          default: true,
          defaultselect: (data) => {
            return data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo
          },
          defaultbody: (data) => {
            return {
              title: data.item.tblConfPersonasempresas[0].coemNombre + " " + data.item.tblConfPersonasempresas[0].coemCodigosubsidiado + " " + data.item.tblConfPersonasempresas[0].coemCodigocontributivo,
              subtitle: data.item.cocoNombre
            }
          },

        },
      ],

      lista: [],
      contratos: [],
      listaCopia: [],
      tipodiagnostico: [],
      tipoclasedx: [],
      medicinas: []
    };
  },

  created: async function() {
    this.$store.commit('setCargaDatos', true);
    await this.buscar(this.filtros);
    this.tipoestado = await this.$apiService.index("sistema/maestra/index/ESTADOS_CITAS").then(data => data);
    this.filtros[4].lista = this.contratos = await this.$apiService.index("sistema/contratos/index").then(data => data);
    this.$store.commit('setCargaDatos', false);
  },

  methods: {
    getEstado(recetarios) {
      let porcentaje = 0;
      recetarios.forEach(recetario => {
        porcentaje = porcentaje + parseFloat(recetario.cireEstadoentrega);
      });
      return ((porcentaje / recetarios.length) * 100);
    },
    calcularEdad(fecha) {
      // Si la fecha es correcta, calculamos la edad

      var values = fecha.split("-");
      var dia = parseInt(values[2]);
      var mes = parseInt(values[1]);
      var ano = parseInt(values[0]);

      // cogemos los valores actuales

      var fecha_hoy = new Date();
      var ahora_ano = fecha_hoy.getFullYear();
      var ahora_mes = fecha_hoy.getMonth() + 1;
      var ahora_dia = fecha_hoy.getDate();

      // realizamos el calculo
      var edad = (ahora_ano + 1900) - ano;
      if (ahora_mes < mes) {
        edad--;
      }
      if ((mes == ahora_mes) && (ahora_dia < dia)) {
        edad--;
      }
      if (edad > 1900) {
        edad -= 1900;
      }

      // calculamos los meses
      var meses = 0;

      if (ahora_mes > mes && dia > ahora_dia)
        meses = ahora_mes - mes - 1;
      else if (ahora_mes > mes)
        meses = ahora_mes - mes
      if (ahora_mes < mes && dia < ahora_dia)
        meses = 12 - (mes - ahora_mes);
      else if (ahora_mes < mes)
        meses = 12 - (mes - ahora_mes + 1);
      if (ahora_mes == mes && dia > ahora_dia)
        meses = 11;

      // calculamos los dias
      var dias = 0;
      if (ahora_dia > dia)
        dias = ahora_dia - dia;
      if (ahora_dia < dia) {
        var ultimoDiaMes = new Date(ahora_ano, ahora_mes - 1, 0);
        dias = ultimoDiaMes.getDate() - (dia - ahora_dia);
      }
      return edad + " años, " + meses + " meses y " + dias + " días";
    },

    async searchPaciente() {
      this.isLoading = true;
      if (this.searchP.length > 0 && this.searchP.toString().replace(/ /g, "") != "") {
        this.$apiService.index("sistema/personas/buscar2/pacientes/" + this.searchP.toUpperCase()).then(data => {
          this.isLoading = false;
          this.pacientes = data;
          this.guardopaciente = false;
        });
      }

    },

    async searchCita(searchP) {
      this.$store.commit('setCargaDatos', true);
      // const data = await this.$apiService.index(`cita/citas/prueba?${searchP}`).then(data => data);
      // if (data) {
      //     this.lista = this.listaCopia = data.results;
      //     this.Count = data.total;
      //     this.pageCount = Math.ceil((data.total) / 10);
      // }
      const data = await this.$apiService.index(`cita/citas/buscar/0?${searchP}tipo=NORMAL`).then(data => data);

      if (data) {
        this.lista = this.listaCopia = data.rows;
        this.Count = data.count;
        this.pageCount = Math.ceil((data.count) / 10);
      }

      this.$store.commit('setCargaDatos', false);
    },
    async buscar(filtros) {
      this.filtros = filtros;
      this.lista = this.listaCopia;
      var search = `offset=${(this.page-1)*10}&`;

      if (this.filtros[0].value != null && this.filtros[0].value.toString().replace(/ /g, "") != "") {
        search = search + `cifaFecha=${this.filtros[0].value}&`;
      }

      if (this.filtros[1].value != null && this.filtros[1].value.toString().replace(/ /g, "") != "") {
        search = search + `estadom=${this.filtros[1].value}&`;

      }
      if (this.filtros[2].value != null && this.filtros[2].value.toString().replace(/ /g, "") != "") {
        search = search + `coppId=${this.filtros[2].value}&`;

      }
      if (this.filtros[3].value != null && this.filtros[3].value.length > 1) {
        search = search + `cifaFecharango=${this.filtros[3].value[0]},${this.filtros[3].value[1]}&`;
        this.filtros[3].value = [];
      }
      if (this.filtros[4].value != null && this.filtros[4].value.toString().replace(/ /g, "") != "") {
        search = search + `cocoId=${this.filtros[4].value}&`;

      }
      if (search != "") {
        await this.searchCita(search);
      }

    },

    async cancelar(id) {
      try {

        let data = await this.$apiService.update(this.url + "cancelar/" + id + "/", null).then(data => data);

        if (data) {
          this.lista = this.lista.filter(e => e.citaId != id);
          this.listaCopia = this.lista.push(data);
          this.$swal.fire({
            title: "Completado!",
            text: "Cita cancelada",
            icon: "success",
            confirmButtonText: "Ok"
          });

        }
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
    async eliminar(id) {
      try {
        var result = await this.$swal.fire({
          title: "Estas seguro?",
          text: "No podras revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, eliminar!"
        }).then(result => result.value)
        if (result) {
          let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
          if (data) {
            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/diagnostico?tipo=CITA");
            this.$apiService.delete("cita/asistenciales/atender/delete/" + id + "/recetario?tipo=CITA");
            this.listaCopia = this.lista = this.lista.filter(
              e => e.citaId != id
            );
            this.$swal.fire(
              "Eliminado!",
              "ha sido eliminado con exito.",
              "success"
            );
          }
        }
      } catch (error) {
        this.$swal.fire({
          title: "Algo sucedio!",
          text: "contacta con el administrador",
          icon: "error"
        });
      }

    },
  }
};

</script>
